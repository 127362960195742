<template>
  <main :class="[mainLayoutStyle, 'main-layout']">
    <slot></slot>
  </main>
</template>

<script>


export default {
  name: 'l-MainLayout',
  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    mainLayoutStyle() {
      return {
        'main-layout--full-screen': this.isFullScreen,
      }
    }
  },
}

</script>

<style lang="scss">

.main-layout {
  display: grid;
  grid-template-columns: 100%;

  @media screen and ($desktop) {
    max-width: 1200px;
    margin: 0 auto;
  }

  &--full-screen {
    min-height: 100vh;
  }

  &--signin-artist,
  &--signup-artist {
    grid-template-columns: 100%;
    grid-template-rows: auto;

    @media screen and ($desktop) {
      grid-template-rows: auto;
      grid-template-columns: 50% 50%;
      max-width: none;
      padding: 0;
    }
  }

  &--artist-emboarding {
    max-height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 300px auto;

    @media screen and ($desktop) {
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      max-width: none;
      padding: 0;
    }
  }

  &--artist-dashboard {
    grid-template-columns: 100%;
    grid-template-rows: 90px;
    padding: 0 var(--space-base);

    @media screen and ($desktop) {
      padding: 0;
    }
  }

  &--artist-profile {
    grid-template-columns: 100%;
    grid-template-rows: repeat(7, auto);
    grid-gap: calc(.8 * var(--space-xl));
    padding: 0 var(--space-base);
    padding-bottom: var(--space-xxl);

    @media screen and ($desktop) {
      grid-gap: var(--space-lg);
      grid-template-columns: 1fr 33%;
      padding: 0;
      padding-bottom: var(--space-xxl);
    }
  }

  &--artist-public-profile {
    grid-template-columns: 100%;
    padding: var(--space-xl) var(--space-base);
    padding-top: 0;
    grid-gap: var(--space-lg);

    @media screen and ($desktop) {
      grid-template-columns: 1fr 33%;
      grid-template-rows: repeat(6, auto);
      padding-top: var(--space-base);
    }
  }

  &--artist-edit-agenda {
    grid-template-rows: 20% 1fr;

    @media screen and ($desktop) {
      grid-template-columns: 1fr 30%;
      grid-template-rows: 1fr;
      margin-left: auto;
      margin-right: auto;
      max-width: none;
    }
  }

  &--service-faq,
  &--service-terms {
    padding: var(--space-md);

    @media screen and ($desktop) {
      padding: var(--space-xl);
    }
  }

  &--not-found {
    grid-template-rows: 1fr 1fr;
    align-items: end;
    justify-items: center;
  }

  &--opening {
    align-items: center;
    justify-items: center;
  }

  &--mail-validation,
  &--mail-validated,
  &--artist-welcome {
    grid-template-rows: 300px auto;

    @media screen and ($desktop) {
      grid-template-rows: 1fr;
      grid-template-columns: 50% 50%;
      max-width: none;
      padding: 0;
    }
  }

  &--password-recovery {
    grid-template-rows: auto 1fr;
    align-items: center;
    justify-items: center;
    padding: var(--space-md);

    @media screen and ($desktop) {
      padding-top: var(--space-header-height);
    }
  }

  &--password-reset {
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
  }

  &--edit-artist-identity,
  &--edit-artist-style,
  &--edit-artist-language,
  &--edit-artist-influences,
  &--edit-artist-equipement,
  &--edit-artist-performance-duration,
  &--edit-artist-pricing,
  &--edit-artist-settings,
  &--edit-artist-location,
  &--edit-artist-instrument,
  &--edit-artist-faq,
  &--edit-artist-activity-general-information,
  &--edit-artist-activity-administrative-status,
  &--edit-locale,
  &--edit-performance,
  &--edit-artist-medias,
  &--edit-artistic-identity {
    align-items: start;
    grid-template-rows: auto 1fr;
  }

  &--artist-prestation-summary {
    grid-template-rows: repeat(5, auto);
    align-items: start;
  }

  &--chat {
    max-width: 100%;
    grid-template-columns: 2fr 5fr;
    grid-template-rows: 80vh;
  }

  &--landing-page {
    grid-template-rows: calc(90vmax - var(--space-header-height));
    align-items: start;
    margin: 0;
    max-width: 100%;

    @media screen and ($desktop) {
      grid-template-rows: calc(60vmin + var(--space-lg));
    }
  }

  &--client-dashboard {
    grid-template-rows: minmax(70px, auto) 1fr;
    padding: 0 var(--space-md);
  }

  &--client-dashboard-search {
    @media screen and ($desktop) {
      grid-template-columns: 1fr 2fr;
      grid-column-gap: var(--space-lg);
      padding: 0;
    }
  }

  &--client-dashboard-filters {
    grid-template-rows: auto 1fr;
    grid-row-gap: var(--space-md);
    padding: var(--space-md);
  }

  &--client-booking {
    grid-template-rows: 70px 1fr;
    background-color: var(--color-grey-background);
    padding: var(--space-md);
  }

  &--verify-phone-number {
    grid-template-rows: repeat(3, auto);
  }

  &--booking-success {
    grid-template-rows: auto auto auto 30%;
  }
}
</style>
